import { storyblokEditable } from '@storyblok/react';
import { SystemProps } from '@storyofams/react-ui';

import { Divider, Accordion, RichText } from '~components';

import { DynamicSectionContainer } from '..';

type FaqProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const Faq = ({ content, ...props }: FaqProps) => {
  return (
      <DynamicSectionContainer
        px={{ all: 2, md: 10, lg: '280px' }}
        {...props}
        space={0}
        {...storyblokEditable(content)}
      >
        <Divider color="grey200" />

        {content?.questions?.map((question) => (
            <Accordion title={question?.question} py={[3, 3, 4]} icon="plus" {...storyblokEditable(question)} key={question?._uid}>
              <RichText
                fontSize={[1.75, 1.75, 2]}
                lineHeight="high"
                text={question?.answer}
              />
            </Accordion>
        ))}
      </DynamicSectionContainer>
  );
};
