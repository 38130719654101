import { storyblokEditable } from '@storyblok/react';
import { SystemProps, css } from '@storyofams/react-ui';
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { FixedRatio } from '~components/common/FixedRatio';
import { DynamicSectionContainer } from '..';
import { Container, Heading, RichText } from "~components";
import { fontSize } from 'styled-system';
import styled from 'styled-components';

type ImageProps = {
  content: any;
  fluid?: [number, number] | number;
} & SystemProps;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  margin-bottom: 0.5em;
`

const Sup = styled.sup`
  font-size: 8px;
  top: 0.5em;
  margin-right: 0.5em;
`

export const References = ({ content, fluid, ...props }: ImageProps) => {
  const { items, title } = content
  return (
    <DynamicSectionContainer {...storyblokEditable(content)}>
      <Container marginY={5}>
        <Heading as="h2" variant="h5" mb={2}>{title}</Heading>
        <ul>
          {items.map((item: any, key: number) => (
          <ListItem key={key}>
            <Sup>{key + 1}</Sup>
            <RichText text={item.text} css={css({
              '& p': {
                margin: 0,
                fontSize: '10px',
              }
            })}/>
          </ListItem>
          ))}
        </ul>
      </Container>
    </DynamicSectionContainer>
  )
};
