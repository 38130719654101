import { useState } from 'react';
import { Box } from '@storyofams/react-ui';
import { AnimatePresence, motion } from 'framer-motion';

import { Slider } from '~components';
import { useTranslations } from '~context';
import { SliderContainer } from '~components/common/Slider';
import { MaterialDetailView } from '~components/Materials/DetailView';
import { SliderItem } from '~components/Materials/SliderItem';
import { storyblokEditable } from '@storyblok/react';

interface MaterialsProps {
  content: any;
  compatibleSeries: Record<string, any>[];
}

export const Materials = ({ content, ...props }: MaterialsProps) => {
  const [detailItem, setDetailItem] = useState(null);
  const { translations } = useTranslations();

  const onMaterialClick = (item) => {
    const newDetailItem =
      detailItem?._uid === item.content._uid ? null : item.content;
    setDetailItem(newDetailItem);
    setTimeout(() => scrollToDetails(true), 300);
  };

  const scrollToDetails = (showDetails: boolean) => {
    const el = document.getElementById(
      showDetails ? 'detailview' : 'slider-container',
    );
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Box position="relative" bg="white">
      <SliderContainer mx="auto" id="slider-container" {...storyblokEditable(content)}>
        <Slider
          breakpoints={{
            1024: { slidesPerView: 'auto', spaceBetween: 48 },
            768: { slidesPerView: 'auto', spaceBetween: 16 },
          }}
        >
          {content.items?.map((item) => (
            <SliderItem
              {...item.content}
              onClick={() => onMaterialClick(item)}
              buttonLabel={
                detailItem?._uid === item?.content?._uid
                  ? translations?.less_details
                  : translations?.more_details
              }
            />
          ))}
        </Slider>
      </SliderContainer>

      <AnimatePresence>
        {!!detailItem && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            transition={{ ease: 'easeInOut', duration: 0.24 }}
          >
            <MaterialDetailView
              content={detailItem}
              closeDetailView={() => {
                setDetailItem(null);
                scrollToDetails(false);
              }}
              {...props}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};
