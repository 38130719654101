import { storyblokEditable } from '@storyblok/react';
import { SystemProps } from '@storyofams/react-ui';
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { FixedRatio } from '~components/common/FixedRatio';
import { DynamicSectionContainer } from '..';
import { Container } from "~components";

type ImageProps = {
  content: any;
  fluid?: [number, number] | number;
} & SystemProps;

export const ImageSection = ({ content, fluid, ...props }: ImageProps) => (
  <DynamicSectionContainer
    isFullWidth={content?.full_width}
    isFullWidthMobile
    {...storyblokEditable(content)}
  >
    <Container>
      <FixedRatio
        width="100%"
        ratio={[
          [328, 162],
          [328, 162],
          content?.full_width ? [1440, 560] : [1200, 400],
        ]}
        bg="grey200"
      >
        <ToolkitImage
          width="100%"
          height="100%"
          src={content?.image.filename}
          alt={content?.image.alt}
          focus={content?.image.focus}
          fluid={fluid || (content?.full_width ? 1440 : 1120)}
        />
      </FixedRatio>
    </Container>
  </DynamicSectionContainer>
);
