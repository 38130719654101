import { Box } from '@storyofams/react-ui';

import { Slider } from '~components';
import { useTranslations } from '~context';
import { SliderContainer } from '~components/common/Slider';
import { SliderItem } from '~components/Materials/SliderItem';
import { storyblokEditable } from '@storyblok/react';
import { Title } from './Title';

interface ProductsProps {
  content: any;
}

export const Products = ({ content, ...props }: ProductsProps) => {
  const { translations } = useTranslations();

  return (
    <Box position="relative" bg="white" {...props} {...storyblokEditable(content)}>
      <SliderContainer>
        <Title
          content={{
            content: [
              { text: content?.title },
              { text: content?.title_bold, component: 'title_bold' },
            ],
          }}
          mb={[5, 5, 6]}
          css={{ boxSizing: 'content-box' }}
          fontSize={[3, 3, '37px']}
          maxWidth={640}
          color="secondary900"
        />

        <Slider
          breakpoints={{
            0: { slidesPerView: 1.8, spaceBetween: 16 },
            1024: { slidesPerView: 4.7, spaceBetween: 42 },
          }}
        >
          {content.products?.map((item) => (
            <SliderItem
              {...item.content}
              subtitle="Color - texture"
              to={item?.content?.full_slug}
              buttonLabel={translations?.configure_this_model}
            />
          ))}
        </Slider>
      </SliderContainer>
    </Box>
  );
};
