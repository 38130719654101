import { storyblokEditable } from '@storyblok/react';
import { Box, SystemProps, Flex } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { Heading, Text, Button } from '~components';
import { getLinkProps } from '~lib';

import { DynamicSectionContainer } from '..';

type ContactSectionProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const ContactSection = ({
  content,
  first,
  ...props
}: ContactSectionProps) => {
  return (
    <DynamicSectionContainer
      py={[7, 7, 10]}
      {...props}
      position="relative"
      bg="white"
      {...storyblokEditable(content)}
    >
      <Box
          display="flex"
          flexDirection={['column', 'column', 'column', 'row']}
        >
        <Box
          mt={[0, 0, 5]}
          maxWidth={[
              '100%',
            '100%',
            '100%',
            'calc(100% - 448px)',
          ]}
        >
          <Heading fontSize={['26px', '26px', '40px']} lineHeight="140%">
            {content?.title}
            <Box as="span" fontWeight="bold">
              &nbsp;{content?.bold_title}
            </Box>
          </Heading>
          <Text variant="l" mt={4} ml={[10, 10, 0]}>
            {content?.description}
          </Text>
          <Flex mt={[49, 49, 54]} alignItems="center">
            <Button
              variant="secondary"
              href={getLinkProps(content?.cta_link)}
              mr={[1, 1, 2]}
            >
              {content?.cta_label}
            </Button>
            <Text variant="s" fontSize={[1.5, 1.5, 1.75]} color="grey700">
              or&nbsp;
            </Text>
            <Button
              variant="link"
              hideArrow
              fontSize={[1.5, 1.5, 1.75]}
              href={getLinkProps(content?.download_link)}
            >
              {content?.download_label}
            </Button>
          </Flex>

          <Box
            ml="auto"
            mr={[-2, -2, -6, 0]}
            mt={[52, 52, 70]}
            bg="grey100"
            width={['295px', '295px', '431px']}
            height={['160px', '160px', '278px']}
          >
            <Image
              width="100%"
              height="100%"
              alt={content?.body_image?.alt || ''}
              src={content?.body_image?.filename}
              fluid={[431, 278]}
              fit="cover"
            />
          </Box>
        </Box>

        <Box
          mt={[2, 2, 2, 0]}
          ml={[0, 0, 0, 5]}
          bg="grey100"
          width={['200px', '200px', '408px']}
          height={['240px', '240px', '527px']}
        >
          <Image
            width="100%"
            height="100%"
            alt={content?.side_image?.alt || ''}
            src={content?.side_image?.filename}
            fluid={[408, 527]}
            fit="cover"
          />
        </Box>
      </Box>
    </DynamicSectionContainer>
  );
};
