import {Box, Flex} from '@storyofams/react-ui';
import { Button, Container, Divider, Heading, variants } from '~components';
import { getLinkProps } from '~lib';
import { Document } from '~components/common/Icons';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';

const icons = {
  document: Document,
};

export const Cta = ({ content }) => {
  return (
    <DynamicSectionContainer bg="primary900" {...storyblokEditable(content)}>
      <Container>
        <Flex
          py={[2.5, 2.5, 10]}
          gap={[1.5]}
          flexDirection='column'
          alignItems="center"
          justifyContent="center"
        >
          {content?.items?.map(({label, link, link_type, icon, title, secondary_link, secondary_link_type, secondary_label}, i) => (
            <>
              <Heading color="white" fontSize={['26px', '26px', '36px']} lineHeight="140%" textAlign={"center"}>
                {title}
              </Heading>

              <Flex
                flexDirection='row'
                gap={[2]}

              >
                {
                  typeof label === 'string' &&
                  label !== '' ?
                    <Button
                      variant={link_type in variants ? link_type : 'primaryLight'}
                      to={getLinkProps(link)}
                      width={['100%', '100%', 'max-content']}
                      icon={icons[icon]}
                    >
                      {label}
                    </Button> : null
                }

                {
                  typeof secondary_label === 'string' &&
                  secondary_label !== '' ?
                    <Button
                      variant={secondary_link_type in variants ? link_type : 'outline'}
                      to={getLinkProps(secondary_link)}
                      width={['100%', '100%', 'max-content']}
                    >
                      {secondary_label}
                    </Button> : null
                }
              </Flex>
            </>
          ))}
        </Flex>
      </Container>
    </DynamicSectionContainer>
  );
};
