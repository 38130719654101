import { SystemProps, Box } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";

import { Heading, Container } from "~components";
import { FixedRatio } from "~components/common/FixedRatio";
import { DynamicSectionContainer } from "..";
import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { getIdFromString } from "~lib/getIdFromString";

type ApplicationImageryProps = {
  content: any;
} & SystemProps;

export const ApplicationImagery = ({ content, ...props }: ApplicationImageryProps) => {
  const hasMaxImages = content?.images?.length === 6;
  if (!hasMaxImages) {
    return undefined;
  }
  
  return (
    <DynamicSectionContainer overflowX="auto" {...storyblokEditable(content)}>
      <Container my={5}>
        <Heading variant="h3" mb={5} id={getIdFromString(content?.title)}>{content?.title}</Heading>{" "}
        <Box {...props}
          display={"grid"}
          gridTemplateColumns={["repeat(6, 80vw)", "repeat(6, 60vw)", "484fr 351fr 484fr 351fr"]}
          overflowX={hasMaxImages ? "auto" : "unset"}
          alignItems="stretch"
          marginX={["-12px", "-40px", "-80px", "-130px"]}
          paddingX={["12px", "40px", "0px"]}
          gap={"20px"}
        >
          {content.images.map((item, index) => {
            if (hasMaxImages) {
              return (
                <GridImage
                  item={item}
                  ratio={
                    index % 3 === 0
                      ? [[1, 1]]
                      : undefined
                  }
                  width={"100%"}
                  height={"100%"}
                  gridRowStart={["1", "1", index % 3 !== 2 ? "1" : index === 2 ? "3" : "2"]}
                  gridRowEnd={["1", "1", index % 3 !== 1 ? "4" : index === 1 ? "3" : "2"]}
                />
              );
            }
          })}
        </Box>
      </Container>
    </DynamicSectionContainer>
  );
};

type GridImageProps = {
  ratio: Array<Array<number>>;
  item: {
    image: {
      filename: string;
      alt: string;
      focus: string;
    };
  }
} & Parameters<typeof Box>[0];

const GridImage = ({ ratio, item, ...props }: GridImageProps) => (
  <Box width="100%"
       height={"100%"} {...props}>
    <FixedRatio width="100%" height={"100%"} ratio={ratio} bg="grey200">
      <ToolkitImage
        width="100%"
        height="100%"
        src={item?.image?.filename}
        alt={item?.image?.alt}
        focus={item?.image?.focus}
        fluid={600}
      />
    </FixedRatio>
  
  </Box>
);
