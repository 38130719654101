import { storyblokEditable } from "@storyblok/react";
import { Box, SystemProps, css } from "@storyofams/react-ui";

import { Accordion, Button, Container, Heading, variants } from "~components";

import { DynamicSectionContainer, RichText } from "..";
import { getLinkProps } from "~lib";
import { getIdFromString } from "~lib/getIdFromString";

type TitleTextProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const TitleTextCombo = ({
  content,
  first,
  ...props
}: TitleTextProps) => {
  const { title, variant, foldable } = content;
  return (
    <>
      <Box display={foldable ? ["none", "none", "none", "block"] : ["block"]}>
        {!!title && (
          <Heading
            as={first ? "h1" : "h2"}
            variant={variant === "left" ? "h3" : "h4"}
            mb={5}
            textAlign={variant === "center" ? "center" : "left"}
            id={getIdFromString(title)}
          >
            {title}
          </Heading>
        )}
        <TitleTextContent content={content} first={first} {...props}/>
      </Box>
      {foldable && (
        <Box display={["block", "block", "block", "none"]}>
          <Accordion title={title} open={true}>
            <TitleTextContent content={content} first={first} {...props}/>
          </Accordion>
        </Box>
      )}
    </>
  );
};

const TitleTextContent = ({content, first, ...props}) => {
  const startsWithBulletPoints = !!content?.text?.content?.[0]?.content?.[0]?.content?.[0]?.content[0]?.text;
  const { text, variant, label, link, link_type } = content;
  
  return (
    <>
      {(!!text || startsWithBulletPoints) && (
        <Box width="100%">
          <RichText
            text={text}
            fontSize={[1.75, 1.75, 4]}
            lineHeight="high"
            css={css({
              textAlign: variant === "center" ? "center" : variant === "left" ? "left" : variant === "right" ? "right" : "left",
              columnCount: variant === "columns" ? [1, 2] : 1,
              columnGap: "48px",
            })}
          />
        </Box>
      )}
      {typeof label === "string" && label !== "" ?
        <Box width="100%" mt={2}
             textAlign={variant === "center" ? "center" : variant === "left" ? "left" : variant === "right" ? "right" : "left"}>
          <Button
            variant={link_type in variants ? link_type : "link"}
            to={getLinkProps(link)}
            width="fit-content"
          >
            {label}
          </Button>
        </Box>
        : null}
    </>
  )
}

export const TitleText = ({ ...props }: TitleTextProps) => {
  if (props?.content?.hide_container) {
    return (
      <DynamicSectionContainer {...storyblokEditable(props?.content)}>
        <Box
          maxWidth={props?.content?.variant === 'columns' ? '100%' : props?.content?.variant === 'center' ? '100%' : 792}
          mx="auto"
          {...props}
        >
          <TitleTextCombo {...props} />
        </Box>
      </DynamicSectionContainer>
    )
  }
  
  return (
    <Container my={10} {...storyblokEditable(props?.content)}>
      <Box
        maxWidth={props?.content?.variant === 'columns' ? '100%' : props?.content?.variant === 'center' ? '100%' : 792}
        mx="auto"
        {...props}
      >
        <TitleTextCombo {...props} />
      </Box>
    </Container>
  );
};
