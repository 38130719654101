import React, { ReactNode, useRef } from "react";
import { Box, SystemProps, Flex, css, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";

import {
  NODE_LI,
  NODE_PARAGRAPH, NODE_UL,
} from "storyblok-rich-text-react-renderer";
import { Text, Button, RichText, Container, Heading } from "~components";

import { DynamicComponents, DynamicSectionContainer, DynamicSections } from "..";
import { storyblokEditable } from "@storyblok/react";
import { useTranslations } from "~context";
import { first } from "lodash";
import { CheckCircle } from "~components/common/Icons";

type IntroProps = {
  content: {
    _uid: string;
    component: string;
    visual: Record<string, any>[];
    title: string;
    description: string;
    links: any
    list?: string;
    hide_configurator_and_downloads?: boolean;
    awards?: {
      alt
      filename
      focus
    };
    bg?: string;
    introduction_image_on_right?: boolean;
  };
  children?: ReactNode;
} & SystemProps;

export const ProductInfoSection = ({ content, children, ...props }: IntroProps) => {
  const { translations } = useTranslations();
  const { title, bg, description, links, hide_configurator_and_downloads, awards, introduction_image_on_right } = content;
  
  const goToDownloads = () => {
    const el = document.getElementById("downloads");
    el?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  
  const goToConfigurator = () => {
    const el = document.getElementById("configurator");
    el?.scrollIntoView({ behavior: "smooth", block: "start" });
    const button = document.getElementById("configurator-start");
    if (!(button.hasAttribute('opened'))) {
      button?.click();
    }
  };
  
  const visual = first(content?.visual);
  
  return (
    <DynamicSectionContainer bg={bg} {...storyblokEditable(content)}>
      <Container py={[5, 5, 8, 100]} {...props}>
        <Box mt={[6, 6, 8, 8]} maxWidth={["100%", "100%", "100%"]}>
          <Heading
            variant="h2"
            as="h3"
            color={content?.bg === "primary900" ? "white" : "black"}
          >{title}
          </Heading>
          
          <Box
            display="grid"
            gridTemplateColumns={["repeat(6, 1fr)", "repeat(8, 1fr)", "repeat(10, 1fr)", "repeat(12, 1fr)"]}
            gridColumnGap="48px"
          >
            <Box
              gridColumn={["span 6", "span 8", "span 10", "span 6"]}
            >
              <RichText
                text={description}
                mt={4}
                mb={4}
                nodeResolvers={{
                  [NODE_PARAGRAPH]: (children) => (
                    <Text color={content?.bg === "primary900" ? "white" : "grey700"} mt={2}
                          variant="m">{children}</Text>
                  ),
                }}
              />
              
              {content?.list && (
                <RichText
                  color={content?.bg === "primary900" ? "white" : "grey700"}
                  text={content?.list}
                  nodeResolvers={{
                    [NODE_UL]: (children) => (
                      <Box as="ul" pl={0}>
                        {children}
                      </Box>
                    ),
                    [NODE_LI]: (children) => (
                      <Flex
                        as="li"
                        color={content?.bg === "primary900" ? "white" : "grey700"}
                        alignItems="flex-start"
                        mb={[1.5, 1.5, 1.75]}
                        css={css({
                          listStyleType: "none",
                        })}
                      >
                        <Icon
                          icon={CheckCircle}
                          color={content?.bg === "primary900" ? "white" : "black"}
                          fontSize={2.25}
                          mr={1.5}
                          mt={0.25}
                        />
                        <Box
                          css={content?.bg === "primary900" ? css({
                            "*": {
                              pb: 0,
                              fontSize: [1.75, 1.75, 2],
                              lineHeight: 1.4,
                              color: "white"
                            },
                          }) : css({
                            "*": {
                              pb: 0,
                              fontSize: [1.75, 1.75, 2],
                              lineHeight: 1.4,
                              color: "grey700"
                            },
                          })}
                        >
                          {children}
                        </Box>
                      </Flex>
                    ),
                  }}
                />
              )}
              
              <Box
                width={[100]}
              >
                {awards?.filename && (
                  <Image src={awards?.filename} alt={awards?.alt} height="100px" fit="contain"/>
                )}
              </Box>
            </Box>
            
            <Box
              gridColumn={["span 6", "span 8", "span 10", `span 6 / ${introduction_image_on_right ? 13 : 7}`]} mt={4}
              gridRowStart={introduction_image_on_right ? 0 : 1}
            >
              <Box
                width={["100%", "100%", "100%", "100%"]}
              >
                {visual?.image?.filename && (
                  <Image src={visual?.image?.filename} alt={visual?.image?.alt} height="100%" fit="contain"/>
                )}
              </Box>
            </Box>
          </Box>
          
          {links ?
            <Flex gap={[2, 2, 2.5]} width={['100%']}><DynamicComponents content={{ sections: links }} variant={'link'}/></Flex>
            :
            null
          }
        
        </Box>
      </Container>
    </DynamicSectionContainer>
  );
};
