import { Box, Flex } from "@storyofams/react-ui";
import { Container, Heading } from "~components";
import { DynamicSectionContainer } from "..";
import { storyblokEditable } from "@storyblok/react";
import { Image } from "@storyofams/storyblok-toolkit";
import React from "react";

export const Logos = ({ content }) => {
  const { title, logos } = content;
  return (
    <DynamicSectionContainer {...storyblokEditable(content)} bg="transparent">
      <Container pb={10} pt={10} bg="transparent">
        <Heading
          as={"h2"}
          variant={["h4", "h4", "h3"]}
          mb={6}
          textAlign={"center"}
          fontWeight={["normal", "normal", "normal"]}
          color={'grey700'}
        >
          {title}
        </Heading>
        <Flex width="100%"
              gap={[6, 6, 10]}
              justifyContent={["center"]}
              alignItems={["center"]}
              flexDirection={["row"]}
              flexWrap={'wrap'}
        >
          {logos?.map(({ image }, i) => (
            <Box
              width={'10%'}
              height={[60]}
              flexShrink={0}
              overflow="hidden"
            >
              <Image src={image?.filename} alt={image?.alt} height="100%" fit="contain"/>
            </Box>
          ))}
        </Flex>
      </Container>
    </DynamicSectionContainer>
  );
};
