import { SystemProps, Box, Flex, css } from '@storyofams/react-ui';
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { first } from 'lodash';

import styled from 'styled-components';
import { Heading, Button, Text, Container, variants } from '~components';
import { getLinkProps } from '~lib';
import { DynamicSectionContainer } from '..';
import ReactPlayer from 'react-player';
import Grid from '~components/common/Grid';
import { Intro } from "~components/dynamic/sections/Intro";
import { storyblokEditable } from "@storyblok/react";

type HeroSectionTypeProps = {
  content: {
    _uid: string;
    visual: Record<string, any>[];
    title: string;
    component: string;
    text?: string;
    subtext?: string;
    bold_title?: string;
    break_bold?: boolean;
    call_to_action_link?: Record<string, any>;
    call_to_action_label?: string;
    call_to_action_type?: string;
    call_to_action_unstyled_link?: Record<string, any>;
    call_to_action_unstyled_label?: string;
    variant?: string;
    bg?: string;
  };
} & SystemProps;

const VisualWrapper = styled.div<{isVideo: boolean }>`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: ${({ isVideo }) => (isVideo ? "auto" : "100%")};
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160vw;

  @media (min-width: 800px) {
    height: 100vw;
  }

  @media (min-width: 1024px) {
    height: 100vh;
    max-height: 900px;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    background: linear-gradient(${(({ theme }) => theme.colors.grey900)} 0%, ${(({ theme }) => theme.colors.grey900)}00 40px, ${(({ theme }) => theme.colors.grey900)}00 40%, ${(({ theme }) => theme.colors.grey900)} 100%);
    z-index: 1;

    @media (min-width: 1024px) {
      background: linear-gradient(${(({ theme }) => theme.colors.grey900)} 0%, ${(({ theme }) => theme.colors.grey900)}00 200px, ${(({ theme }) => theme.colors.grey900)}00 -200px, ${(({ theme }) => theme.colors.grey900)} 100%);
    }
  }
`;

const VisualMediumWrapper = styled.div<{ isVideo: boolean }>`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: ${({ isVideo }) => (isVideo ? "auto" : "100%")};
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160vw;

  @media (min-width: 800px) {
    height: 100vw;
  }

  @media (min-width: 1024px) {
    height: 676px;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    background: linear-gradient(90deg, ${(({ theme }) => theme.colors.grey900)} 1.27%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
`;

const ReactPlayerWrapper = styled.div`
  min-height: 56.25vw;
  min-width: 100vw;
  z-index: -1;
`;

export const HeroSection = ({ content, ...props }: HeroSectionTypeProps) => {
  if (!content) {
    return null;
  }
  
  const visual = first(content?.visual);
  
  return (
    <DynamicSectionContainer
      py={0}
      isFullWidth
      isFullWidthMobile
      position="relative"
      {...props}
      {...storyblokEditable(content)}
    >
      <Box
        position="relative"
      >
        <Hero
          {...content}
          variant={content?.variant}
          video={visual?.video}
          image={visual?.image}
        />
      </Box>
    </DynamicSectionContainer>
  );
};

export const Hero = ({
  variant,
  video,
  image,
  title,
  bold_title,
  text,
  break_bold,
  subtext,
  rich_subtext,
  call_to_action_label,
  call_to_action_link,
  call_to_action_type,
  call_to_action_unstyled_label,
  call_to_action_unstyled_link
}: any) => {
  const description = text
  const bg = 'primary900'

  switch (variant) {
    case 'intro':
      return (
        <Intro
          pt={[4, 4, 55]}
          pb={[4, 4, 10]}
          justifyContent="end"
          minHeight='640px'
          maxHeight='640px'
          content={{
            title,
            bold_title,
            break_bold,
            description,
            subtext,
            rich_subtext,
            bg
          }}
        />
      )
    case 'large':
      return (
        <Box
          width="100%"
          bg="grey900"
          overflow="hidden"
          maxHeight="900px"
          minHeight="500px"
          css={css({
            aspectRatio: ["300/480", "400/480", "375/480", "unset"],
          })}
        >
          <VisualWrapper isVideo={video}>
            {image?.filename && (
              <ToolkitImage
                fit="cover"
                showPlaceholder={false}
                height='100%'
                width='100%'
                src={image?.filename}
                alt={image?.alt || 'Hero image'}
                focus={image?.focus}
              />
            )}
            
            {video && (
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      controls: 0,
                      muted: 1,
                    },
                  },
                  vimeo: {
                    playerOptions: {
                      controls: false,
                      muted: true,
                    },
                  },
                }}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                wrapper={ReactPlayerWrapper}
              />
            )}
          </VisualWrapper>
          <Box bottom={0} width="100%" height="100%">
            <Container
              position={'absolute'}
              bottom={[5, 5, 10, '120px']}
              zIndex={1}
              left={0}
              right={0}
            >
              <Grid>
                {title && (
                  <Heading
                    variant="hero"
                    color="white"
                    as="h1"
                    gridColumn={["span 5", "span 7", "span 9", "span 9", "span 9"]}
                    fontSize={[28, 40, 52, 52, 64]}
                    lineHeight={[1.4, 1.15]}
                  >
                    {title}{' '}
                    
                    {bold_title && (
                      <Box as="span" display={break_bold ? "block" : 'inline'}  maxWidth={break_bold ? "750px" : 'auto'} fontWeight="bold">
                        {bold_title}
                      </Box>
                    )}
  
                    {subtext && (
                      <Heading
                        mt={4}
                        as={"h2"}
                        variant="h3"
                        color="white"
                        gridColumn={["span 5", "span 7", "span 9", "span 9", "span 9"]}
                      >
                        {subtext}
                      </Heading>
                    )}
                  </Heading>
                )}
                
                <Flex
                  mt={[3, 5]}
                  gridRow={[3, 3, 2, 2]}
                  gridColumn={["span 4", "span 4", "span 4", "span 4"]}
                  gap={[2, 2, 3, 3]}
                >
                  {call_to_action_label && call_to_action_link && (
                    <Button
                      variant={call_to_action_type in variants ? call_to_action_type : 'link'}
                      to={getLinkProps(call_to_action_link)}
                      width="fit-content"
                    >
                      {call_to_action_label}
                    </Button>
                  )}
                  {call_to_action_unstyled_label && call_to_action_unstyled_link && (
                    <Button
                      variant={'unstyled'}
                      to={getLinkProps(call_to_action_unstyled_link)}
                      width="fit-content"
                    >
                      {call_to_action_unstyled_label}
                    </Button>
                  )}
                </Flex>
                {text && (
                  <Text
                    color="white"
                    mt={[3, 4, 5, "60px"]}
                    gridColumn={["span 7", "span 7", "5 / span 6", "5 / span 8",  "7 / span 6"]}
                    gridRow={"2"}
                    fontSize={[2, 2, 2, '23px']}
                    lineHeight={1.4}
                    fontWeight="350"
                  >
                    {text}
                  </Text>
                )}
              </Grid>
            </Container>
          </Box>
        </Box>
      )
    case 'small':
      return (
        <Box
          width="100%"
          bg="grey900"
          overflow="hidden"
          maxHeight="500px"
          css={css({
            aspectRatio: ["300/480", "375/480", "375/480", "unset"],
          })}
        >
          <VisualMediumWrapper isVideo={video}>
            {image?.filename && (
              <ToolkitImage
                fit="cover"
                showPlaceholder={false}
                height='100%'
                width='100%'
                src={image?.filename}
                alt={image?.alt || 'Hero image'}
                focus={image?.focus}
              />
            )}
            
            {video && (
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      controls: 0,
                      muted: 1,
                    },
                  },
                  vimeo: {
                    playerOptions: {
                      controls: false,
                      muted: true,
                    },
                  },
                }}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                wrapper={ReactPlayerWrapper}
              />
            )}
          </VisualMediumWrapper>
          <Box position="absolute" bottom={0} width="100%" height="100%">
            <Container
              height={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              zIndex={1}
            >
              <Grid
                display={['flex', 'grid', 'grid']}
              >
                {title && (
                  <Heading
                    variant="hero"
                    color="white"
                    as="h1"
                    gridColumn={["span 5", "span 6", "span 7", "span 9", "span 7", "span 6"]}
                    fontSize={[28, 40, 52, 64]}
                    lineHeight={[1.4, 1.15]}
                  >
                    {title}{' '}
                    
                    {bold_title && (
                      <Box as="span" display={break_bold ? "block" : 'inline'}  maxWidth={break_bold ? "750px" : 'auto'} fontWeight="bold">
                        {bold_title}
                      </Box>
                    )}
                  </Heading>
                )}
                
                {((call_to_action_label && call_to_action_link) || (call_to_action_unstyled_label && call_to_action_unstyled_link)) ? (
                <Flex
                  gridRow={[3]}
                  mt={[3, 5]}
                  gridColumn={"span 6"}
                  gap={[2, 2, 3, 3]}
                >
                  {call_to_action_label && call_to_action_link && (
                    <Button
                      color="white"
                      variant={call_to_action_type in variants ? call_to_action_type : 'link'}
                      to={getLinkProps(call_to_action_link)}
                      width="fit-content"
                    >
                      {call_to_action_label}
                    </Button>
                  )}
                  {call_to_action_unstyled_label && call_to_action_unstyled_link && (
                    <Button
                      color="white"
                      variant="unstyled"
                      to={getLinkProps(call_to_action_unstyled_link)}
                      width="fit-content"
                    >
                      {call_to_action_unstyled_label}
                    </Button>
                  )}
                </Flex>
                ) : null}
              </Grid>
            </Container>
          </Box>
        </Box>
      )
    case 'medium':
    default:
      return (
        <Box
          width="100%"
          bg="grey900"
          overflow="hidden"
          maxHeight="640px"
          css={css({
            aspectRatio: ["300/480", "375/480", "375/480", "unset"],
          })}
        >
          <VisualMediumWrapper isVideo={video}>
            {image?.filename && (
              <ToolkitImage
                fit="cover"
                showPlaceholder={false}
                height='100%'
                width='100%'
                src={image?.filename}
                alt={image?.alt || 'Hero image'}
                focus={image?.focus}
              />
            )}
            
            {video && (
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      controls: 0,
                      muted: 1,
                    },
                  },
                  vimeo: {
                    playerOptions: {
                      controls: false,
                      muted: true,
                    },
                  },
                }}
                playing={true}
                loop={true}
                muted={true}
                playsinline={true}
                wrapper={ReactPlayerWrapper}
              />
            )}
          </VisualMediumWrapper>
          <Box position="absolute" bottom={0} width="100%" height="100%">
            <Container
              height={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              zIndex={1}
            >
              <Grid
                display={['flex', 'grid', 'grid']}
              >
                {title && (
                  <Heading
                    variant="hero"
                    color="white"
                    as="h1"
                    gridColumn={["span 5", "span 6", "span 7", "span 9", "span 7", "span 6"]}
                    fontSize={[28, 40, 52, 64]}
                    lineHeight={[1.4, 1.15]}
                  >
                    {title}{' '}
                    
                    {bold_title && (
                      <Box as="span" display={break_bold ? "block" : 'inline'}  maxWidth={break_bold ? "750px" : 'auto'} fontWeight="bold">
                        {bold_title}
                      </Box>
                    )}
                  </Heading>
                )}
                
                {text && (
                  <Text
                    color="white"
                    mt={[2]}
                    gridColumn={["span 5", "span 7", "span 6", "span 6"]}
                    gridRow={"2"}
                    fontSize={2}
                    lineHeight={1.4}
                    fontWeight="350"
                  >
                    {text}
                  </Text>
                )}
                {((call_to_action_label && call_to_action_link) || (call_to_action_unstyled_label && call_to_action_unstyled_link)) ? (
                  <Flex
                    gridRow={[3]}
                    mt={[3, 5]}
                    gridColumn={"span 6"}
                    gap={[2, 2, 3, 3]}
                  >
                    {call_to_action_label && call_to_action_link && (
                      <Button
                        color="white"
                        variant={call_to_action_type in variants ? call_to_action_type : 'link'}
                        to={getLinkProps(call_to_action_link)}
                        width="fit-content"
                      >
                        {call_to_action_label}
                      </Button>
                    )}
                    {call_to_action_unstyled_label && call_to_action_unstyled_link && (
                      <Button
                        color="white"
                        variant="unstyled"
                        to={getLinkProps(call_to_action_unstyled_link)}
                        width="fit-content"
                      >
                        {call_to_action_unstyled_label}
                      </Button>
                    )}
                  </Flex>
                ) : null }
              </Grid>
            </Container>
          </Box>
        </Box>
      )
  }
};
