import { storyblokEditable } from '@storyblok/react';
import { SystemProps, Box } from '@storyofams/react-ui';

import { Heading } from '~components';

import { DynamicSectionContainer } from '..';

type HeaderSmallProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const HeaderSmall = ({ content, first, ...props }: HeaderSmallProps) => (
  <DynamicSectionContainer {...props} bg={content?.bg} position="relative" {...storyblokEditable(content)}>
    <Heading
      as={first ? 'h1' : 'h2'}
      variant="h1"
      color="secondary900"
      maxWidth={['100%', '100%', '50%']}
      minWidth={['unset', 'unset', '588px']}
    >
      {content.title}
      <Box as="span" fontWeight="bold">
        &nbsp;{content.bold_title}
      </Box>
    </Heading>
  </DynamicSectionContainer>
);
