import { Box, SystemProps, Flex, css, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { Accordion, Button, Heading, Modal, RichText } from "~components";
import { DynamicSectionContainer } from "..";
import { storyblokEditable } from "@storyblok/react";
import { useTranslations } from "~context";
import React, { useState } from "react";
import { Close } from "~components/common/Icons";
import { getIdFromString } from "~lib/getIdFromString";

type ProductSchematicsProps = {
  content: any;
} & SystemProps;

export const ProductSchematics = ({ content, ...props }: ProductSchematicsProps) => {
  const { title, foldable } = content;
  
  return (
    <DynamicSectionContainer {...props} bg={content?.bg} px={0} {...storyblokEditable(content)} alignSelf={"flex-start"} height={'100%'}>
      <Box display={foldable ? ["none", "none", "none", "block"] : ["block"]}>
        <Flex flexDirection={"column"} gap={1}>
          <Heading
            variant="h3"
            as={"h3"}
            lineHeight="140%"
            maxWidth={640}
            gridColumn={["span 5", "span 7", "span 7"]}
            padding={0}
            id={getIdFromString(title)}
          >
            <Box as="span" fontWeight="normal">
              {title}
            </Box>
          </Heading>
          <ProductSchematicsContent content={content} {...props} />
        </Flex>
      </Box>
      {foldable && (
        <Box display={["block", "block", "block", "none"]}>
          <Accordion title={title}>
            <ProductSchematicsContent content={content} {...props} />
          </Accordion>
        </Box>
      )}
    </DynamicSectionContainer>
  );
};

const ProductSchematicsContent = ({content, ...props}: ProductSchematicsProps) => {
  const { image, clickable_image } = content;
  
  const [ modalOpen, setModalOpen ] = useState(false);
  
  return (
    <Box my={3} position={'relative'}>
      {image?.filename && (
        <Button
          variant="unstyled"
          display="block"
          onClick={clickable_image ? () => setModalOpen(true) : undefined}
          pointerEvents={clickable_image ? "auto" : "none"}
          height={'100%'}
          width={'100%'}
        >
          <Image src={image?.filename} alt={image?.alt} height="100%" fit='contain' />
        </Button>
      )}
      {clickable_image && (<Modal isOpen={modalOpen} width={["90vw"]} maxWidth={"90vw"} maxHeight={"90vh"} padding="0 0 10px">
          <Box position={"relative"} height={"70vh"} css={css({
            "& > div": {
              // ignore error, this is a styled-components issue
              // @ts-ignore
              "position": "static !important",
            }
          })}>
            <Button
              variant="unstyled"
              display="block"
              onClick={() => setModalOpen(false)}
              position="absolute"
              top="0"
              right="0"
              zIndex={2}
              padding="10px"
            >
              <Icon icon={Close} />
            </Button>
            <Image src={image?.filename} alt={image?.alt} height="100%" fit='contain' />
          </Box>
        </Modal>
      )}
    </Box>
  )
}