import { SystemProps, Box, Flex } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import {
  Button,
  FixedRatio,
  Heading,
  HoverArea,
  ScaleOnHover,
} from '~components';
import { getLinkProps } from '~lib';
import { ImageWrapper } from '~components/common/Navigation/components/Gallery';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';

type ProductSeriesProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const ProductSeries = ({
  content,
  first,
  ...props
}: ProductSeriesProps) => {
  return (
    <DynamicSectionContainer {...props} bg={content?.bg} {...storyblokEditable(content)}>
      <Flex flexDirection="column">
        <Heading
          variant="h2"
          as={first ? 'h1' : 'h2'}
          lineHeight="140%"
          maxWidth={{ md: '640px' }}
          mb={[4, 4, 4, 5]}
          pr={[8, 8, 8, 0]}
        >
          {content.title}{' '}
          <Box as="span" fontWeight="bold">
            {content.bold_title}
          </Box>
        </Heading>

        <Flex order={[2, 2, 1]} mb={[0, 0, 2]} mt={[4, 4, 0]}>
          <Button
            variant="secondary"
            href={getLinkProps(content?.call_to_action_link)}
          >
            {content.call_to_action_label}
          </Button>
        </Flex>

        <Flex order={[1, 1, 2]} ml={['unset', 'unset', 'auto']}>
          <Box
            display="grid"
            gridRowGap={[1.5, 2.5, 6]}
            gridColumnGap={[1.5, 2.5, 6]}
            gridTemplateColumns="repeat(2, 1fr)"
            width={['100%', '100%', 'fit-content']}
          >
            {content?.categories?.map((item) => (
              <Button
                variant="unstyled"
                to={`/product-series#${item.slug}`}
                width="100%"
                position="relative"
                minHeight={[150, 260]}
                minWidth={{ md: 400 }}
              >
                <HoverArea>
                  <FixedRatio
                    ratio={[
                      [150, 166],
                      [260, 400],
                    ]}
                    maxHeight={[150, 260]}
                    width="100%"
                    overflow="hidden"
                  >
                    <ScaleOnHover>
                      <ImageWrapper width="100%" height="100%">
                        <Image
                          alt={item?.content?.image?.alt}
                          src={item?.content?.image?.filename}
                          fluid={550}
                          width="100%"
                          height="100%"
                        />
                      </ImageWrapper>
                    </ScaleOnHover>
                  </FixedRatio>

                  <Box
                    position="absolute"
                    bottom={[2, 3]}
                    left={[2, 3]}
                    display="flex"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="link"
                      hideArrow
                      fontSize={[1.5, 2.25]}
                      color="white"
                      fontWeight="bold"
                      lineHeight={1}
                      css={{
                        '&:not(:disabled)': {
                          '&:hover, &:active, &:focus': {
                            color: 'white',
                          },
                        },
                      }}
                    >
                      {item?.content?.name}
                    </Button>
                  </Box>
                </HoverArea>
              </Button>
            ))}
          </Box>
        </Flex>
      </Flex>
    </DynamicSectionContainer>
  );
};
