import styled from "styled-components";
import Grid from "../../../common/Grid"
import { Container } from "../../../common/Layout/Container"
import { Box } from "@storyofams/react-ui";
import { RichText } from "~components/common/RichText";
import { storyblokEditable } from "@storyblok/react";

const StyledContainer = styled(Container)`
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledGrid = styled(Grid)`
  column-gap: 48px;
  row-gap: 48px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    row-gap: 0;
  }
`;

const Column = styled(Box)`
  grid-column: span 6;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    grid-column: span 4;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-column: span 5;
  }
  
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-column: span 6;
  }

  & p {
    color: ${props => props.theme.colors.grey800};
    letter-spacing: normal;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 14px;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 18px;
  }
`;

type TwoColumnTextProps = {
  content: any;
}

export const TwoColumnText = ({
  content,
  ...props
}: TwoColumnTextProps) => {
  return (
    <StyledContainer {...storyblokEditable(content)}>
      <StyledGrid>
        <Column>
          <RichText text={content.left_text} />
        </Column>
        <Column>
          <RichText text={content.right_text} />
        </Column>
      </StyledGrid>
    </StyledContainer>
  );
}