import { SystemProps, Box, Flex } from '@storyofams/react-ui';
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';

import { Text, Button } from '~components';
import { getLinkProps } from '~lib';
import { FixedRatio } from '~components/common/FixedRatio';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';

type ImageGridProps = {
  content: any;
} & SystemProps;

export const ImageGrid = ({ content, ...props }: ImageGridProps) => {
  const hasMaxImages = content?.images?.length === 3;

  return (
    <DynamicSectionContainer overflowX="auto" {...storyblokEditable(content)}>
      <Flex
        {...props}
        overflowX={hasMaxImages ? 'auto' : 'unset'}
        flexDirection={hasMaxImages ? 'row' : ['column', 'row']}
        width={hasMaxImages ? ['max-content', '100%'] : 'unset'}
        alignItems="center"
        gap={[4, 4, 6]}
      >
        {content.images.map((item, index) => {
          if (hasMaxImages) {
            return (
              <GridImage
                item={item}
                ratio={getRatioForIndex(index)}
                minWidth={[229, 229, 'unset']}
              />
            );
          }

          return (
            <GridImage
              item={item}
              width="100%"
              ratio={
                index === 0
                  ? [
                      [343, 399],
                      [487, 586],
                    ]
                  : [
                      [359, 245],
                      [585, 400],
                    ]
              }
            />
          );
        })}
      </Flex>
    </DynamicSectionContainer>
  );
};

const getRatioForIndex = (index: number) => {
  if (index === 0) {
    return [
      [229, 256],
      [368, 440],
    ];
  } else if (index === 1) {
    return [
      [229, 381],
      [368, 586],
    ];
  } else {
    return [
      [229, 232],
      [368, 400],
    ];
  }
};

const GridImage = ({ ratio, item, ...props }) => (
  <Box width="100%" {...props}>
    <FixedRatio width="100%" ratio={ratio} bg="grey200">
      <ToolkitImage
        width="100%"
        height="100%"
        src={item?.image?.filename}
        alt={item?.image?.alt}
        focus={item?.image?.focus}
        fluid={600}
      />
    </FixedRatio>

    {item?.helptext && (
      <Text
        variant="s"
        fontSize={[1.5, 1.5, 2]}
        lineHeight={['135%', '135%', 1.5]}
        color="grey700"
        mt={[1.5, 1.5, 2]}
      >
        {item?.helptext}
      </Text>
    )}

    {item?.call_to_action_label && item?.call_to_action_link && (
      <Button
        variant="link"
        width="max-content"
        href={getLinkProps(item?.call_to_action_link)}
        mt={2.25}
      >
        {item?.call_to_action_label}
      </Button>
    )}
  </Box>
);
