import { Box, SystemProps } from "@storyofams/react-ui";
import { Heading, Container, Text } from "~components";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";

type ContactFormProps = {
  content: any;
} & SystemProps;

export const ContactForm = ({ content, ...props }: ContactFormProps) => (
  <Container my={4} pb={10} id={typeof content?.form_anchor === 'string' && content?.form_anchor !== '' ? content?.form_anchor : 'form'}>
    <Box mx="auto" maxWidth={"700px"}>
      <Heading variant="h4" as="h4" my={4}>
        {content?.title}
      </Heading>
      <StyledFormContainer>
        <HubspotForm
          portalId={content?.hubspot_portal_id}
          formId={content?.hubspot_form_id}
          loading={<Container><Text variant="l">Loading...</Text></Container>}
        />
      </StyledFormContainer>
    </Box>
  </Container>
);

const StyledFormContainer = styled(Box)`
  form {
    fieldset,
    fieldset.form-columns-2,
    fieldset.form-columns-1 {
      max-width: 100%;
      position: relative;
      padding-bottom: ${(p) => p.theme.space[3]}px;

      label {
        color: ${(p) => p.theme.colors.grey800};
        font-size: ${(p) => p.theme.fontSizes[1.75]}px;
        font-family: ${(p) => p.theme.fonts.body};
      }

      .input {
        margin: 0;
      }

      .hs-form-field {
        flex: 1;

        input {
          background-color: ${(p) => p.theme.colors.white};
          border: ${(p) => p.theme.borders['1px']} ${(p) => p.theme.colors.secondary100};
          border-radius: ${(p) => p.theme.radii.xs};
          width: 100%;
          min-height: 44px;
          padding: 0 ${(p) => p.theme.space[1]}px;

          &:focus, &:active, &:hover {
            border: ${(p) => p.theme.borders['1px']} ${(p) => p.theme.colors.primary};
          }

          @media (max-width: 400px), (min-device-width: 320px) and (max-device-width: 480px) {
            width: 100% !important;
          }
        }

        textarea.hs-fieldtype-textarea {
          background-color: ${(p) => p.theme.colors.white};
          border: ${(p) => p.theme.borders['1px']} ${(p) => p.theme.colors.secondary100};
          border-radius: ${(p) => p.theme.radii.xs};
          width: 100%;
          min-height: 139px;

          &:focus, &:active, &:hover {
            border: ${(p) => p.theme.borders['1px']} ${(p) => p.theme.colors.primary};
          }
        }

        @media (max-width: 400px), (min-device-width: 320px) and (max-device-width: 480px) {
          flex: 0 0 100%;
        }
      }

      a {
        color: ${(p) => p.theme.colors.primary};

        &:hover {
          color: ${(p) => p.theme.colors.primary800};
        }
      }

      .inputs-list {
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        gap: ${(p) => p.theme.space[3]}px;

        &.multi-container {
          margin-top: ${(p) => p.theme.space[4]}px;
        }

        li input {
          min-height: fit-content;
          width: fit-content !important;
        }

        span {
          margin-left: ${(p) => p.theme.space[1]}px;
        }
      }

      .hs-error-msgs {
        position: absolute;
        bottom: 0;
        
        li label {
          color: ${(p) => p.theme.colors.error};
        }
      }
    }

    fieldset.form-columns-2 {
      display: flex;
      flex-flow: row wrap;
      gap: ${(p) => p.theme.space[3]}px;
    }

    .legal-consent-container .hs-richtext {
      font-size: ${(p) => p.theme.fontSizes[1.5]}px;
    }

    .hs_recaptcha .grecaptcha-badge {
      display: none;
    }

    .hs_submit input {
      font-size: ${(p) => p.theme.fontSizes[1.75]}px;
      font-weight: ${(p) => p.theme.fontWeights.medium};
      color: ${(p) => p.theme.colors.white};
      border: ${(p) => p.theme.borders.none};
      background-color: ${(p) => p.theme.colors.primary};
      border-radius: ${(p) => p.theme.radii.full};

      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${(p) => p.theme.space[1.5]}px ${(p) => p.theme.space[4]}px;

      &:focus, &:active, &:hover {
        background-color: ${(p) => p.theme.colors.primary800};
        cursor: pointer;
      }
    }
  }

`;
