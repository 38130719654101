import { SystemProps, Box, Flex } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";

import { Text, Button, Container, variants, Heading } from "~components";
import { getLinkProps } from "~lib";
import { FixedRatio } from "~components/common/FixedRatio";
import { storyblokEditable } from "@storyblok/react";
import { DynamicSectionContainer } from "~components/dynamic";

type GallerySectionProps = {
  content: any;
} & SystemProps;

export const GallerySection = ({ content, ...props }: GallerySectionProps) => {
  const { title, gallery_type } = content;
  return (
    <DynamicSectionContainer bg={content?.bg} {...storyblokEditable(content)}>
      <Container py={10}>
        <Heading
          variant="h2"
          as="h2"
          fontWeight="bold"
          lineHeight="140%"
          mb={[4, 4, 6, 8]}
          css={{ boxSizing: "content-box" }}
        >
          {title}
        </Heading>
        <Flex
          {...props}
          overflowX={"unset"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"auto"}
          alignItems={gallery_type === "unique_cards" ? "self-end" :"stretch"}
          justifyContent={gallery_type === "unique_cards" ? "center" : "flex-start"}
          gap={[3, 3, 3]}
        >
          {content.images.map((item, index) => {
            return (
              <GalleryImage
                item={item}
                bg={gallery_type === "unique_cards" ? 'transparent' : 'white'}
                flex={"0 1 100%"}
                maxWidth={gallery_type === "small_cards" ? "176px" : gallery_type === "medium_cards" ? "277px" : gallery_type === "unique_cards" ? "377px" : "377px"}
                ratio={
                    gallery_type === "small_cards" ?
                    [
                      [343, 399],
                      [487, 586]
                    ] :
                    gallery_type === "medium_cards" ?
                      [
                        [384, 265],
                        [384, 265]
                      ]
                      :
                    gallery_type === "unique_cards" && index !== 1 ?
                      [
                        [384, 400],
                        [384, 400]
                      ]
                      :
                    gallery_type === "unique_cards" && index === 1 ?
                      [
                        [384, 400],
                        [384, 600]
                      ]
                      :
                      [
                        [384, 265],
                        [384, 265]
                      ]
                }
              />
            );
          })}
        </Flex>
      </Container>
    </DynamicSectionContainer>
  );
};

const GalleryImage = ({ ratio, item, ...props }) => (
  <Box width="100%" pb={2}{...props}>
    <FixedRatio width="100%" ratio={ratio} bg="grey200">
      <ToolkitImage
        width="100%"
        height="100%"
        src={item?.image?.filename}
        alt={item?.image?.alt}
        focus={item?.image?.focus}
        fluid={600}
      />
    </FixedRatio>
    
    {item?.title && (
      <Heading
        variant="h4"
        as="h4"
        fontWeight="bold"
        lineHeight="140%"
        my={2}
        css={{ boxSizing: "content-box" }}
      >
        {item?.title}
      </Heading>
    )}
    
    {item?.text && (
      <Text
        variant="s"
        fontSize={[1.5, 1.5, 2]}
        lineHeight={["135%", "135%", 1.5]}
        color="grey700"
        mt={[1.5, 1.5, 2]}
      >
        {item?.text}
      </Text>
    )}
    
    {item?.label && item?.link && (
      <Button
        variant={item?.link_type in variants ? item?.link_type : "link"}
        width="max-content"
        href={getLinkProps(item?.link)}
        mt={2.25}
      >
        {item?.label}
      </Button>
    )}
  </Box>
);
