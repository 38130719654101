import { storyblokEditable } from '@storyblok/react';
import { Flex } from '@storyofams/react-ui';
import { CaseItem } from '~components/CaseItem';
import { DynamicSectionContainer } from '..';

export const Applications = ({ content, ...props }) => {
  const itemCount = content?.items?.length || 0;
  return (
      <DynamicSectionContainer {...props} bg={content?.bg} overflowX="auto" {...storyblokEditable(content)}>
        <Flex
          gap={[2, 2, 3]}
          flexDirection="row"
          flex={1}
          flexShrink={0}
          width={[
            itemCount * 200 + 16 * (itemCount - 1),
            itemCount * 200 + 16 * (itemCount - 1),
            '100%',
          ]}
        >
          {content?.items?.map((item) => (
            <CaseItem
              isFeatured
              full_slug={item.full_slug}
              content={{ case: [item.content] }}
            />
          ))}
        </Flex>
      </DynamicSectionContainer>
  );
};
