import { SystemProps, Box, css, Flex } from '@storyofams/react-ui';
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';

import {
  Text,
  Button,
  FixedRatio,
  containerPadding,
  LegacyContainer,
} from '~components';
import { DynamicSectionContainer } from '..';
import { Title } from '.';
import { storyblokEditable } from '@storyblok/react';

type ContactCardProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const ContactCard = ({ content, first, ...props }: ContactCardProps) => {
  return (
      <DynamicSectionContainer space={0} position="relative" {...props} px={0} {...storyblokEditable(content)}>
        <LegacyContainer pt={0} pb={0}>
          <Title
            content={content?.title?.[0]}
            fontSize={[26, 26, 40]}
            maxWidth={588}
            mb={[4, 4, 4, 4, 0]}
          />
        </LegacyContainer>
        <Flex justifyContent="flex-end" px={{ ...containerPadding, all: 0 }}>
          <Box
            p={['24px 22px 24px 16px', '24px 22px 24px 16px', 4]}
            width="100%"
            maxWidth={['100%', '100%', '100%', 600]}
            bg="secondary"
            display="flex"
            ml="auto"
            alignItems="center"
          >
            <FixedRatio
              width="100%"
              mr={[2, 2, 3]}
              maxWidth={[105, 105, 120]}
              overflow="hidden"
              maxHeight={[140, 140, 160]}
              ratio={[
                [105, 140],
                [105, 140],
                [120, 160],
              ]}
              bg="grey200"
              css={css({
                '.storyblok-image-wrapper': {
                  width: '100%',
                  height: '100%',
                },
              })}
            >
              <ToolkitImage
                width="100%"
                height="100%"
                src={content?.image?.filename}
                alt={content?.image?.alt}
                focus={content?.image?.focus}
                fluid={600}
              />
            </FixedRatio>
            <Box>
              <Text
                color="secondary900"
                lineHeight="high"
                fontSize={[2, 2, 2.25]}
                mb={0.5}
              >
                {content.name}
              </Text>
              <Text
                color="grey700"
                lineHeight={['high', 'high', 1.5]}
                fontSize={[1.5, 1.5, 2]}
                mb={3}
              >
                {content.description}
              </Text>
              <Button variant="secondary" bg="white">
                {content.call_to_action_label}
              </Button>
            </Box>
          </Box>
        </Flex>
      </DynamicSectionContainer>
  );
};
