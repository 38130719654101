import { Box, SystemProps, Flex, css } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { Accordion, Heading, RichText } from "~components";
import { DynamicSectionContainer } from "..";
import { storyblokEditable } from "@storyblok/react";
import { getIdFromString } from "~lib/getIdFromString";

type SpecificationsTableProps = {
  content: any;
} & SystemProps;

export const SpecificationsTable = ({ content, ...props }: SpecificationsTableProps) => {
  const { title, foldable } = content;
  return (
    <DynamicSectionContainer {...props} bg={content?.bg} px={0} {...storyblokEditable(content)} alignSelf={"flex-start"} height={'100%'}>
      <Box display={foldable ? ["none", "none", "none", "block"] : ["block"]}>
        <Flex flexDirection={"column"} gap={1}>
          <Heading
            variant="h3"
            as={"h3"}
            lineHeight="140%"
            maxWidth={640}
            gridColumn={["span 5", "span 7", "span 7"]}
            padding={0}
            id={getIdFromString(title)}
          >
            <Box as="span" fontWeight="normal">
              {title}
            </Box>
          </Heading>
          
          <Box my={3}>
            <SpecificationsTableContent content={content} {...props} />
          </Box>
        </Flex>
      </Box>
      {foldable && (
        <Box display={["block", "block", "block", "none"]}>
          <Accordion title={title}>
            <SpecificationsTableContent content={content} {...props} />
          </Accordion>
        </Box>
      )}
    </DynamicSectionContainer>
  );
};

const SpecificationsTableContent = ({content, ...props}: SpecificationsTableProps) => {
  const { specifications } = content;
  return (
    <>
      {specifications?.map((item) => (
        <Flex
          flexDirection="row"
          justifyContent={"space-between"}
          gap={2}
          mt={1}
          borderBottomStyle={"solid"}
          borderBottomWidth={1}
          borderBottomColor={"grey300"}
          justifyItems={"center"}
        >
          <Box as="span" width={"50%"} color={"black"} fontWeight={"bold"}>
            {item?.key}
          </Box>
          
          <Flex width={"50%"} flexFlow={"row"} flexWrap={"wrap"} gap={2} justifyItems={"center"} alignItems={"center"}>
            <RichText text={item?.value} textColor={"black"} fontWeight={"bold"}/>
            {item?.image?.filename && (
              <Box mb={1}>
                <Image
                  width="38px"
                  height="38px"
                  alt={item?.image?.alt || "alt for image"}
                  src={item?.image?.filename}
                  fluid={500}
                  fit="cover"
                />
              </Box>
            )}
          </Flex>
        </Flex>
      ))}
    </>
  )
}
