import { useState } from "react";
import { Box, SystemProps, css, Flex, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { first as getFirst } from "lodash";

import { Heading, Slider, Button, Text, FixedRatio, Container } from "~components";
import { getLinkProps } from "~lib";
import { Play } from "~components/common/Icons";
import { VideoOverlay } from "~components/VideoOverlay";

import { sliderPadding } from "~components/common/Slider";
import { Video } from ".";
import { storyblokEditable } from "@storyblok/react";
import { useTranslations } from "~context";

type ScrollBarProps = {
  items: Record<string, any>;
  datasource: string;
  display_type: string;
  bg?: string;
};

type ScrollBarContentProps = {
  item: Record<string, any>
  
  display_type: string;
  bg?: string;
  setPlaying?: any;
}

type ScrollBarReferencedContentProps = {
  item: Record<string, any>
  name: string;
  url: string;
  display_type: string;
}

type ScrollBarSectionProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const ContentSwitcher = ({ item, datasource, display_type, bg, setPlaying }) => {
  switch (datasource) {
    case "categories":
      return (<ScrollBarReferencedContent item={item} url={`/product-series#${item.slug}`} name={item?.content?.name}
                                          display_type={display_type}/>);
    case "materials":
    case "products":
      return (<ScrollBarReferencedContent item={item} url={`/${item?.full_slug}`} name={item?.name}
                                          display_type={display_type}/>);
    default:
      return (<ScrollBarContent item={item} bg={bg} setPlaying={setPlaying} display_type={display_type}/>);
  }
};

export const PrimaryButton = ({ label, link }) => {
  return (
    <Container
      mt={2}
      mb={10}
    >
      <Button
        variant="primary"
        to={getLinkProps(link)}
        width={["100%", "100%", "max-content"]}
      >
        {label}
      </Button>
    </Container>
  );
};

export const ScrollBar = ({ items, datasource, display_type, bg }: ScrollBarProps) => {
  const [playing, setPlaying] = useState(null);
  
  return (
    <>
      <Slider
        sliderType={"scrollbar"}
        breakpoints={{
          1024: { slidesPerView: "auto", spaceBetween: 16 },
          768: { slidesPerView: "auto", spaceBetween: 16 },
        }}
      >
        {items?.map((item) => {
          return (
            <ContentSwitcher
              key={item._uid}
              datasource={datasource} 
              item={item} 
              display_type={display_type} 
              bg={bg}
              setPlaying={setPlaying}
            />
          )
        })}
      </Slider>
      
      <VideoOverlay isOpen={playing} src={playing} setOpen={setPlaying}/>
    </>
  );
};

export const ScrollBarContent = ({ item, display_type, bg, setPlaying }: ScrollBarContentProps) => {
  
  return (
    <Button
      variant="unstyled"
      href={getLinkProps(item.call_to_action_link)}
    >
      <Box
        border="1px"
        borderColor={
          !bg || ["white", "grey", "#FFFFFF", "#F5F6F6"].includes(bg)
            ? "grey200"
            : "transparent"
        }
        display="flex"
        flexDirection="column"
        minWidth={display_type === "medium" ? "246px" : ["345px", "345px", "383px"]}
        maxWidth={["200px", "200px", "486px"]}
        mb={5}
      >
        <FixedRatio
          flex={1}
          ratio={
            display_type === "medium" ?
              [
                [123, 158],
                [123, 158],
                [246, 316],
              ] :
              [
                [192, 133],
                [192, 133],
                [383, 265],
              ]
          }
          overflow="hidden"
          width="100%"
          maxHeight={display_type === "medium" ? [158, 158, 316] : [133, 133, 265]}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {item?.media?.[0]?.component === "image" ? (
            <Image
              width="100%"
              height="100%"
              alt={
                // @ts-ignore
                getFirst(item.media)?.image?.alt || "alt for image"
              }
              // @ts-ignore
              src={getFirst(item.media)?.image?.filename}
              fluid={500}
              fit="cover"
            />
          ) : (
            <>
              <Box
                width="100%"
                height="100%"
                onClick={() => setPlaying(item?.media?.[0]?.video)}
                cursor="pointer"
              >
                <Icon
                  icon={Play}
                  color="white"
                  fontSize={6}
                  position="absolute"
                  zIndex={1}
                  left={`calc(50% - 24px)`}
                  top={`calc(50% - 24px)`}
                />
                <Video
                  ratio={[
                    [200, 120],
                    [486, 264],
                  ]}
                  width="100%"
                  height="100%"
                  controls={false}
                  content={{ url: item?.media?.[0]?.video }}
                  hasOverlay
                  config={{
                    youtube: {
                      playerVars: {
                        modestbranding: 1,
                        controls: false,
                      },
                    },
                  }}
                />
              </Box>
            </>
          )}
        </FixedRatio>
        
        <Flex
          flex={1}
          flexDirection="column"
          p={[1.5, 1.5, 3]}
          pb={[1.25, 1.25, 4]}
          bg="white"
          px={display_type === "medium" ? [2] : [2]}
        >
          <Heading
            variant="h4"
            mb={[0.75, 0.75, 1.25]}
            fontWeight="bold"
            fontSize={[1.75, 1.75, "23px"]}
          >
            {item.title}
          </Heading>
          
          <Text variant="s" mb={2} fontSize={[1.5, 1.5, 2]}>
            {item.summary}
          </Text>
          
          <Button
            variant="link"
            fontSize={[1.75]}
            ml={["auto", "auto", 0]}
            justifyContent={display_type === "medium" ? "center" : "left"}
          >
            <Box as="span" display={["none", "none", "flex"]}>
              {item.call_to_action_label}
            </Box>
          </Button>
        </Flex>
      </Box>
    </Button>
  );
};

export const regionCleanerForReferences = ({ url }: { url: string }): string => {
  // Replace the folder structure of the url.
  return url.replace(/\/(la|in|anz|as|eu)\//, '/');
}

export const ScrollBarReferencedContent = ({ item, url, name, display_type }: ScrollBarReferencedContentProps) => {
  const { translations } = useTranslations();
  url = regionCleanerForReferences({ url });
  
  return (
    <Box
      border="1px"
      borderColor={"white"}
      display="flex"
      flexDirection="column"
      minWidth={display_type === "medium" ? "246px" : ["345px", "345px", "383px"]}
      maxWidth={["200px", "200px", "486px"]}
    >
      <FixedRatio
        flex={1}
        ratio={
          display_type === "medium" ?
            [
              [123, 158],
              [123, 158],
              [246, 316],
            ] :
            [
              [192, 133],
              [192, 133],
              [383, 265],
            ]
        }
        overflow="hidden"
        width="100%"
        maxHeight={display_type === "medium" ? [158, 158, 316] : [133, 133, 265]}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          width="100%"
          height="100%"
          alt={item?.content?.image?.alt}
          src={item?.content?.image?.filename}
          fluid={500}
          fit="cover"
        />
      </FixedRatio>
      <Flex
        flex={1}
        flexDirection="column"
        pt={3}
        pb={[1.25, 1.25, 4]}
        px={display_type === "medium" ? [2] : [2]}
        bg="white"
      >
        <Button
          variant="unstyled"
          href={url}
          justifyContent={display_type === "medium" ? "center" : "left"}
        >
          <Heading
            variant="h4"
            mb={[0.75, 0.75, 1.25]}
            fontWeight="bold"
            color={"black"}
            fontSize={
              display_type === "medium" ?
                [1.75, 1.75, 1.75] :
                [1.75, 1.75, "23px"]
            }
          >
            {name}
          </Heading>
        </Button>
        
        <Button
          variant={display_type === "medium" ? "primaryLight" : "link"}
          href={url}
          fontSize={[1.75]}
          ml={[0]}
        >
          <Box as="span" color={display_type === "medium" ? "white" : "black"} display={["flex"]}>
            {display_type === "medium" ? translations?.configure_this_model : translations?.discover}
          </Box>
        </Button>
      </Flex>
    </Box>
  );
};

export const ScrollBarSection = ({
  content,
  first,
  ...props
}: ScrollBarSectionProps) => {
  const { bold_title, title, items, categories, materials, products, datasource, display_type, label, link } = content;
  let data = datasource === 'categories'
    ? categories : datasource === 'materials'
    ? materials : datasource === 'products'
    ? products : items
  
  return (
    <>
      <Box
        {...props}
        overflow="hidden"
        bg={content?.bg}
        mx={0}
        mt={title === "" && bold_title === "" ? 2 : 10}
        px={0}
        maxWidth="100%"
        css={css({
          "max-width": "100%",
        })}
        {...storyblokEditable(content)}
      >
        {title === "" && bold_title === "" ? null :
          <Heading
            variant="h2"
            px={sliderPadding}
            as={first ? "h1" : "h2"}
            lineHeight="140%"
            mb={[4, 4, 8]}
            css={{ boxSizing: "content-box" }}
            color={"black"}
            maxWidth={640}
          >
            <Box as="span" fontWeight="normal">
              {title}{" "}
            </Box>
            <Box as="span" fontWeight="bold">
              {bold_title}
            </Box>
          </Heading>
        }
        
        <ScrollBar items={data} datasource={datasource} display_type={display_type} bg={content?.bg}/>
        {typeof label === "string" && label !== "" ? <PrimaryButton label={label} link={link}/> : ""}
      </Box>
    </>
  );
};
