import { Box, Flex } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { Heading, Button, Text, FixedRatio } from '~components';
import { ProductSeriesOverviewQuery } from '~lib/storyblok/sdk';

interface SliderItemProps {
  title?: string;
  name?: string;
  subtitle: string;
  image: ProductSeriesOverviewQuery['OverviewItem']['content']['image'];
  buttonLabel: string;
  onClick?(): void;
  to?: string;
}

export const SliderItem = ({
  title,
  name,
  subtitle,
  image,
  buttonLabel,
  onClick,
  to,
}: SliderItemProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      border="1px solid transparent"
      maxWidth={[200, 200, 368]}
    >
      <FixedRatio
        ratio={[
          [180, 224],
          [180, 224],
          [240, 300],
        ]}
        overflow="hidden"
        width="100%"
        position="relative"
        alignItems="center"
        justifyContent="center"
        minWidth="min-content"
        maxWidth={['200px', '200px', '486px']}
      >
        <Image
          width="100%"
          height="100%"
          alt={image?.alt || 'alt for image'}
          src={image?.filename}
          fluid={500}
          fit="cover"
        />
      </FixedRatio>

      <Flex
        flexDirection="column"
        pt={[2, 2, 3]}
        bg="white"
        justifyContent="center"
        alignItems="center"
      >
        <Heading as="h6" fontWeight="bold" fontSize={2} lineHeight="150%">
          {title || name}
        </Heading>

        <Text variant="s" mb={[2, 2, 3]} fontSize={1.75} lineHeight="150%">
          {subtitle}
        </Text>

        <Button
          variant="secondary"
          fontSize={[1.5, 1.5, 1.75]}
          onClick={onClick}
          to={to}
          mx={[0, 0, 2.25]}
        >
          {buttonLabel}
        </Button>
      </Flex>
    </Box>
  );
};
