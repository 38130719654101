import { ReactNode, useMemo } from 'react';
import { Box, SystemProps, Flex, Icon, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import {
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer';
import { Text, Button, FixedRatio, RichText, Container, Heading } from "~components";
import { getLinkProps } from '~lib';
import { CheckCircle } from '~components/common/Icons';

import { DynamicSectionContainer } from '..';
import { Title } from '.';
import { storyblokEditable } from '@storyblok/react';

type IntroProps = {
  content: any;
  first?: boolean;
  children?: ReactNode;
} & SystemProps;

export const Intro = ({ content, first, children, ...props }: IntroProps) => {
  const { bold_title, break_bold, bg, subtext, rich_subtext, description } = content;
  
  const title = useMemo(() => {
    let titleContent;

    if (typeof content?.title === 'string') {
      titleContent = { content: [] };
      titleContent?.content?.push({ text: content?.title });

      if (
        typeof content?.title === 'string' &&
        typeof bold_title === 'string' &&
        break_bold === false
      ) {
        titleContent?.content?.push({
          text: bold_title,
          component: 'title_bold',
        });
      }
    }

    return titleContent;
  }, [content?.title, bold_title]);

  return (
      <DynamicSectionContainer bg={bg} {...storyblokEditable(content)}>
        <Container py={[5, 5, 8, 100]} {...props}>
        <Flex flexDirection={['column', 'column', 'column', 'row']}>
          <Box mt={[6, 6, 8, 8]} maxWidth={['100%', '100%', '100%', 564]}>
            <Title
              first={first}
              content={title || content?.title}
              mr={[8, 8, 8, -100]}
              color={bg === 'primary900' ? 'white' : 'black'}
            />
            {break_bold === true ?
              <Heading
                variant="h1"
                as="h3"
                mr={[8, 8, 8, -100]}
                color={content?.bg === 'primary900' ? 'white' : 'black'}
              >{bold_title}</Heading>
              :
              null}
  
            {rich_subtext ? (
              <RichText
                text={rich_subtext}
                mt={4}
                nodeResolvers={{
                  [NODE_PARAGRAPH]: (children) => (
                    <Text color={content?.bg === 'primary900' ? 'white' : 'black'} fontWeight={700} mt={2} variant="l">{children}</Text>
                  ),
                }}
              />
            ) : subtext && (
              <Heading
                mt={4}
                as={"h2"}
                variant="h4"
                color="white"
                gridColumn={["span 5", "span 7", "span 9", "span 9", "span 9"]}
              >
                {subtext}
              </Heading>
            )}
          </Box>

          <Box
            mt={[3, 3, 3, '148px']}
            ml={[8, 8, 8, 148]}
            maxWidth={['100%', '100%', '100%', 564]}
          >
            {description && (
              <Flex flexDirection="column" gap={[3, 3, 3, 5]}>
                {typeof description === 'string' ? (
                  <Text color={content?.bg === 'primary900' ? 'white' : 'black'} variant="l">{description}</Text>
                ) : (
                  <RichText
                    text={description}
                    nodeResolvers={{
                      [NODE_PARAGRAPH]: (children) => (
                        <Text color={content?.bg === 'primary900' ? 'white' : 'black'} variant="l">{children}</Text>
                      ),
                    }}
                  />
                )}

                {content?.list && (
                  <RichText
                    color={content?.bg === 'primary900' ? 'white' : 'black'}
                    text={content?.list}
                    nodeResolvers={{
                      [NODE_UL]: (children) => (
                        <Box as="ul" pl={0}>
                          {children}
                        </Box>
                      ),
                      [NODE_LI]: (children) => (
                        <Flex
                          as="li"
                          color={content?.bg === 'primary900' ? 'white' : 'black'}
                          alignItems="flex-start"
                          mb={[1.5, 1.5, 1.75]}
                          css={css({
                            listStyleType: 'none',
                          })}
                        >
                          <Icon
                            icon={CheckCircle}
                            color={content?.bg === 'primary900' ? 'white' : 'black'}
                            fontSize={2.25}
                            mr={1.5}
                            mt={0.25}
                          />
                          <Box
                            css={content?.bg === 'primary900' ? css({
                              '*': {
                                pb: 0,
                                fontSize: [1.75, 1.75, 2],
                                lineHeight: 1.4,
                                color: 'white'
                              },
                            }) : css({
                              '*': {
                                pb: 0,
                                fontSize: [1.75, 1.75, 2],
                                lineHeight: 1.4,
                                color: 'black'
                              },
                            })}
                          >
                            {children}
                          </Box>
                        </Flex>
                      ),
                    }}
                  />
                )}

                {((content.call_to_action_link &&
                  content.call_to_action_label) ||
                  children) && (
                  <Box
                    width={['calc(100% + 64px)', 'calc(100% + 64px)', '100%']}
                    ml={[-8, -8, 0]}
                  >
                    <>
                      {children}

                      {content.call_to_action_link &&
                        content.call_to_action_label && (
                          <Button
                            width="max-content"
                            variant={
                              !!content?.call_to_action_variant
                                ? content?.call_to_action_variant
                                : 'link'
                            }
                            to={getLinkProps(content.call_to_action_link)}
                          >
                            {content.call_to_action_label}
                          </Button>
                        )}
                    </>
                  </Box>
                )}
              </Flex>
            )}

            {content?.image_right?.filename && (
              <FixedRatio
                ratio={[[564, 564, 360]]}
                display={['none', 'none', 'none', 'block']}
                position="relative"
                mt={10}
                ml={-100}
                width="100%"
                maxWidth={564}
                overflow="hidden"
              >
                <Image
                  alt={content?.image_right?.alt}
                  src={content?.image_right?.filename}
                  fluid={440}
                  width="100%"
                  height="100%"
                />
              </FixedRatio>
            )}
          </Box>

          {content?.image?.filename && (
            <FixedRatio
              ratio={[[241, 143]]}
              display={['block', 'block', 'block', 'none']}
              mt={4}
              ml={[-2, -2, -6]}
              width="100%"
              maxWidth="65%"
              overflow="hidden"
            >
              <Image
                alt={content?.image?.alt}
                src={content?.image?.filename}
                fluid={440}
                width="100%"
                height="100%"
              />
            </FixedRatio>
          )}

          {content?.image_right?.filename && (
            <FixedRatio
              ratio={[[184, 156]]}
              display={['block', 'block', 'block', 'none']}
              width="100%"
              maxWidth="50%"
              mt={[2, 2, 4]}
              ml="auto"
              overflow="hidden"
            >
              <Image
                alt={content?.image_right?.alt}
                src={content?.image_right?.filename}
                fluid={440}
                width="100%"
                height="100%"
              />
            </FixedRatio>
          )}
        </Flex>
        </Container>
      </DynamicSectionContainer>
  );
};
