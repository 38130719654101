import { SystemProps, Box, Flex, Grid } from "@storyofams/react-ui";
import { DynamicSectionContainer, DynamicSections } from "..";
import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { Container } from "~components";

type ProductSpecificationsProps = {
  content: any;
} & SystemProps;

export const ProductSpecifications = ({ content, ...props }: ProductSpecificationsProps) => {
  return (
    <DynamicSectionContainer overflowX="auto" {...storyblokEditable(content)}>
      <Container my={5}>
        <Box
          mt={3}
          display="grid"
          flexDirection={"column"}
          gap={6}
          gridTemplateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
        >
          <DynamicSections content={{ sections: content?.content }} pt={0} pb={0}/>
        </Box>
      </Container>
    </DynamicSectionContainer>
  );
};