import { storyblokEditable } from "@storyblok/react";
import { SystemProps, Box, Grid, Icon, Flex } from "@storyofams/react-ui";

import { Divider, Button, Text, Heading, Container, Accordion } from "~components";
import { Download } from "~components/common/Icons";
import { DynamicSectionContainer } from "~components/dynamic";
import { prop } from "dom7";
import { getIdFromString } from "~lib/getIdFromString";

type DownloadSectionProps = {
  content: any;
} & SystemProps;

export const DownloadSection = ({
  content,
  ...props
}: DownloadSectionProps) => {
  const { title, foldable } = content;
  
  return (
    <DynamicSectionContainer {...props} {...storyblokEditable(content)} height={'100%'}>
      
      <Box pb={[0, 0, 2]} id="downloads">
        <Box display={foldable ? ["none", "none", "none", "block"] : ["block"]}>
          <Heading
            variant="h3"
            as="h3"
            mb={4}
            id={getIdFromString(content.title)}
          >
            <Flex>
              {content.title}
              <Icon icon={Download} ml={2} fontSize={[3, 3, 6]} color={"primary"}/>
            </Flex>
          </Heading>
          <DownloadSectionContent content={content} {...prop} />
        </Box>
        {foldable && (
          <Box display={["block", "block", "block", "none"]}>
            <Accordion title={title}>
              <DownloadSectionContent content={content} {...prop} />
            </Accordion>
          </Box>
        )}
      </Box>
    </DynamicSectionContainer>
  );
};

const DownloadSectionContent = ({content, ...props}: DownloadSectionProps) => {
  return (
    <Flex
      flexDirection={"column"}
      gap={2}
    >
      {content?.assets?.map((asset) => (
        <Button
          key={asset.filename}
          variant="unstyled"
          href={asset.filename}
          color="black"
          alignItems="center"
          download={asset.title}
          minWidth={{
            all: "25%",
            xs: "100%",
            sm: "50%",
            md: "33%",
            lg: "25%",
          }}
        >
          <Icon icon={Download} mr={1} fontSize={[3, 3, 4]} color={"primary900"}/>
          <Text
            color="black"
            fontSize={[1.5, 1.5, 2]}
            lineHeight="150%"
            fontWeight="bold"
            letterSpacing="-0.01em"
          >
            {asset.title ||
              asset?.filename?.split("/")[
              asset?.filename?.split("/")?.length - 1
                ]}
          </Text>
        </Button>
      ))}
    </Flex>
  )
}