import { Box, Flex, Icon } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import {
  Heading,
  Button,
  Text,
  FixedRatio,
  RichText,
  Divider,
} from '~components';
import { useTranslations } from '~context';
import { Close } from '~components/common/Icons';
import { DynamicSectionContainer } from '../dynamic';

interface MaterialDetailViewProps {
  content: Record<string, any>;
  compatibleSeries: Record<string, any>[];
  closeDetailView: () => void;
}

export const MaterialDetailView = ({
  content,
  compatibleSeries,
  closeDetailView,
  ...props
}: MaterialDetailViewProps) => {
  const { translations } = useTranslations();
  return (
    <DynamicSectionContainer
      {...props}
      mt={[0, 0, 8]}
      bg="grey200"
      position="relative"
      id="detailview"
    >
      <Flex
        width="100%"
        pt={[8, 8, 88]}
        pb={[4, 4, 88]}
        justifyContent={['center']}
        flexDirection={['column', 'column', 'row']}
      >
        <Flex
          flex={1}
          height="100%"
          mr={5}
          ml={[5, 5, 0]}
          alignSelf="center"
          maxWidth={['263px', '263px', '534px']}
          minWidth={['calc(100% - 80px)', 'calc(100% - 80px)', 'unset']}
        >
          <FixedRatio
            ratio={[
              [263, 266],
              [263, 266],
              [534, 534],
            ]}
            maxHeight={[266, 266, 534]}
            overflow="hidden"
            width="100%"
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              width="100%"
              height="100%"
              alt={content?.image?.alt || 'alt for image'}
              src={content?.image?.filename}
              fluid={500}
              fit="cover"
            />
          </FixedRatio>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Heading
            variant="h3"
            as="h3"
            fontSize={['22px', '22px', '32px']}
            lineHeight="high"
            fontWeight="bold"
            mt={[4, 4, 2.25]}
          >
            {content?.title}
          </Heading>
          <Text variant="l">{content?.subtitle}</Text>

          <Divider mt={[2.25, 2.25, 2]} mb={[2, 2, 4]} />

          {!!compatibleSeries?.length && (
            <Box mb={[3, 3, '27px']}>
              <Heading
                as="h6"
                fontWeight="bold"
                fontSize={2}
                lineHeight="150%"
                mb={2}
              >
                {translations?.compatible_series}
              </Heading>
              <Flex gap={[1.5, 1.5, 2]}>
                {compatibleSeries?.map((serie) => (
                  <Box bg="white" width={[166, 166, 200]}>
                    <FixedRatio
                      ratio={[
                        [166, 120],
                        [166, 120],
                        [200, 120],
                      ]}
                      overflow="hidden"
                      width="100%"
                      maxHeight={[120]}
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        width="100%"
                        height="100%"
                        alt={serie?.content?.thumbnail?.alt || 'alt for image'}
                        src={serie?.content?.thumbnail?.filename}
                        fluid={500}
                        fit="cover"
                      />
                    </FixedRatio>

                    <Box p={[1.5]} pb={1.75}>
                      <Heading
                        as="h6"
                        fontWeight="bold"
                        fontSize={1.75}
                        lineHeight="high"
                      >
                        {serie?.content?.label}
                      </Heading>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Box>
          )}
          <RichText text={content?.description} />
        </Flex>
      </Flex>
      <Button
        variant="unstyled"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        right={[2, 2, 8, 15]}
        top={[2, 2, 5, 5]}
        zIndex={2}
        onClick={closeDetailView}
      >
        <Icon
          icon={Close}
          fontSize={3}
          color="grey800"
          size="24px"
          cursor="pointer"
        />
      </Button>
    </DynamicSectionContainer>
  );
};
