import { storyblokEditable } from '@storyblok/react';
import { Box } from '@storyofams/react-ui';
import { Heading } from '~components';
import { DynamicSectionContainer } from '..';

export const Title = ({ first = false, content, ...props }) => {
  return (
    <Heading
      variant="h1"
      as={first ? 'h1' : 'h2'}
      lineHeight="140%"
      maxWidth={880}
      {...props}
    >
      {content?.content?.map(({ component, text, ...temp }, i) => {
        const key = `${text.replace(/ /g, "-").toLowerCase()}-${i}`;
        return (<>
          <Box key={key} as="span" fontWeight={component === 'title_bold' ? 'bold' : 'normal'}>
            {text}
            {i === 0 ? ' ' : ''}
          </Box>
          {i === 0 && content?.breakLine && <br key={`${key}-break`} />}
        </>)
      })}
    </Heading>
  );
};

export const TitleSection = ({ first, content, ...props }) => {
  return (
    <DynamicSectionContainer bg={content?.bg} {...props} {...storyblokEditable(content)}>
      <Title content={content} first={first} />
    </DynamicSectionContainer>
  );
};
