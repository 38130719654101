import { SystemProps, Box, Flex } from "@storyofams/react-ui";
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';

import { Text, Container, Heading, Accordion } from "~components";
import { FixedRatio } from '~components/common/FixedRatio';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';
import React from "react";
import { getIdFromString } from "~lib/getIdFromString";

type ProductImageGridProps = {
  content: any;
} & SystemProps;

export const ProductImageGrid = ({ content, ...props }: ProductImageGridProps) => {
  return (
    <>
      {content?.hide_container ?
        <DynamicSectionContainer {...storyblokEditable(content)} height={"100%"}>
          <ProductImageGridContent content={content} {...props} />
        </DynamicSectionContainer> :
        <DynamicSectionContainer {...storyblokEditable(content)}>
          <Container my={5}>
            <ProductImageGridContent content={content} {...props} />
          </Container>
        </DynamicSectionContainer>
      }
    </>
  );
};

const ProductImageGridContent = ({ content, ...props }) => (
  <>
    <Box display={content?.foldable ? ["none", "none", "none", "block"] : ["block"]}>
      {content?.title && (<Heading variant="h3" id={getIdFromString(content?.title)} mb={5}>{content?.title}</Heading>)}
      <GridContent content={content} {...props} />
    </Box>
    {content?.foldable && (
      <Box display={["block", "block", "block", "none"]}>
        <Accordion title={content?.title ?? ''}>
          <GridContent content={content} {...props}/>
        </Accordion>
      </Box>
    )}
  </>
);

const GridContent = ({ content, ...props }) => (
  <>
    <Flex {...props} gap={[4, 4, 6]} flexFlow={'row'} flexWrap={content?.variant === 'small' ? 'wrap' : 'nowrap'}>
      {content.images.map((item, index) => {
        return (
          <GridImage
            key={index}
            item={item}
            ratio={[[1,1]]}
            maxWidth={content?.variant === 'small' ? '69px' : '20%'}
            gridColumn={["span 3", "span 2", "span 2", "span 2"]}
          />
        );
      })}
    </Flex>
  </>
);

const GridImage = ({ ratio, item, ...props }) => (
  <Box width="100%" {...props}>
    <FixedRatio width="100%" ratio={ratio} bg="grey200">
      <ToolkitImage
        width={"100%"}
        height={"100%"}
        src={item?.image?.filename}
        alt={item?.image?.alt}
        focus={item?.image?.focus}
        fluid={600}
      />
    </FixedRatio>

    {item?.helptext && (
      <Text
        variant="s"
        fontSize={[1.5, 1.5, 2]}
        lineHeight={['135%', '135%', 1.5]}
        color="grey700"
        mt={[1.5, 1.5, 2]}
      >
        {item?.helptext}
      </Text>
    )}
  </Box>
);
